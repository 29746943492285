@import "../../../common/style/comon-themes";

:root,
.ion-palette-dark.ios,
.ion-palette-dark.md {
  /** primary **/
  --ion-color-primary: #feb92b;
  --ion-color-primary-rgb: 254,185,43;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e0a326;
  --ion-color-primary-tint: #fec040;

  --ion-color-warning: #fa7d00;
  --ion-color-warning-rgb: 250,125,0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #dc6e00;
  --ion-color-warning-tint: #fb8a1a;
}
